import { gettext } from "#js/components/i18n"
import { fetchJSON } from "#js/components/http"
import { createTour, getButton, MODAL_PADDING } from "#js/integrations/tours/tourUtils"

function getSteps(tour) {
  return [
    {
      id: "language-tour-welcome",
      text: gettext(
        "Did you know? 🌍 With switching the language we also show you different content." +
          "<br><br><b>Curious how? Click on the language icon above and try it yourself!</b>",
      ),
      advanceOn: { selector: "[data-tour='language-choice']", event: "click" },
      attachTo: {
        element: '[data-tour="language-choice"]',
        on: "bottom-end",
      },
      modalOverlayOpeningPadding: MODAL_PADDING.MEDIUM,
    },
    {
      id: "language-tour-finished",
      text: gettext(
        "You are all set! 🎉</b>" +
          "<br><br>You can change languages anytime using the language icon.",
      ),
      buttons: [getButton(tour, "done")],
    },
  ]
}

/**
 * Initialize the language switch tour
 * This tour guides the user through the language switch feature.
 * The tour is shown on the program page.
 * The tour is shown only once per user.
 * The tour is shown only if the sales product tour is not shown.
 * The tour is shown only if the language choice step has not been shown in a finished sales product tour
 * @returns {Promise<void>} - A promise that resolves when the tour is initialized
 */
export async function initLanguageSwitchTour() {
  const productTourSettings = await fetchJSON(globalThis.productTourSettingsPath)
  // Avoid collision with the sales product tour
  if (productTourSettings.should_show_tour) {
    return
  }
  // Do not show language choice step if already shown in sales product tour
  if (productTourSettings.steps_shown.includes("language-choice")) return

  const languageTourFinished =
    await globalThis.userStorage.getItem("language-tour-finished") === "true"
  if (languageTourFinished) return

  const tour = createTour()

  for (const step of getSteps(tour)) {
    if (
      globalThis.location.pathname.endsWith(globalThis.programPath) &&
      await globalThis.userStorage.getItem(step.id) === null
    ) {
      tour.addStep(step)
    }
  }

  tour.on("show", (data) => {
    if (data.step.id !== "language-tour-finished") {
      userStorage.setItem(data.step.id, "true")
    }
  })

  tour.on("complete", () => {
    globalThis.userStorage.setItem("language-tour-finished", "true")
  })

  tour.start()
}
